import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import {PortfolioItemsWrapper, CardItem, ImageContainer, Image, HorizontalList, CardItemText, CardLinkDiv, CardLinkButton} from "../styles";

/*Component*/

const PortfolioItems = () => {
    const data = useStaticQuery(graphql`
    query {
      allContentfulPortfolio(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            title
            description {
                description
              }
            techStackList
            subtitle
            demoLink
            date(formatString: "DD-MM-YYYY")
            image {
              fluid(maxWidth: 600) {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                src
                srcSet
                sizes
                aspectRatio
              }
              }
            }
          }
        }
      }
    
    `)


return (
 
    <PortfolioItemsWrapper>
     {data.allContentfulPortfolio.edges.map((edge) => {
            const { title, subtitle, image, id, description, demoLink,techStackList} = edge.node        
            return (
              <CardItem key={id} >
                <ImageContainer>
                  <Img fluid={image.fluid} alt={image.title} style={Image} />
                </ImageContainer>
                <CardItemText>
                    <h3>{title}</h3>
                    <p>{subtitle}</p>
                    <HorizontalList role="presentation">
                        {techStackList.map(tech => <li key={tech}>{tech}</li>)}
                    </HorizontalList>
                    <p>{description.description}</p>
                    <CardLinkDiv>
                        <CardLinkButton href={demoLink} target="_blank" rel="noreferrer">Demo</CardLinkButton>
                    </CardLinkDiv>
                </CardItemText>
              </CardItem>
            )})}
    </PortfolioItemsWrapper>
)}

export default PortfolioItems;
