import React from "react";
import Layout from "../components/Layout/layout";
import PortfolioItems from "../components/PortfolioItems/portfolioItems";
import { graphql, useStaticQuery } from 'gatsby'
import '../components/page-styles/pages.css';

/*Component*/

const Portfolio = () => {
  const data = useStaticQuery(graphql`
  query {
    contentfulPortfolioPageIntro {
      title
      description {
        description
      }
    }
  }
  `)
  const {title, description} = data.contentfulPortfolioPageIntro
  return (
    <Layout>
      <div className="text-wrapper">
            <h1 className="title">{title}</h1>
            <p className="paragraph">{description.description}</p> 
      </div>
      <PortfolioItems/>
    </Layout>
)};

export default Portfolio;